const navbar  = document.getElementsByClassName('navbar')[0]
const tabs    = document.getElementsByClassName('tabs')[0]
const banner  = document.getElementsByClassName('banner')[0]

if (navbar && tabs) {
  window.addEventListener('scroll', detectCollision)
}

function detectCollision () {
  const navbarCoord = navbar.getBoundingClientRect()
  const tabsCoord   = tabs.getBoundingClientRect()
  const bannerCoord = banner.getBoundingClientRect()

  if ((tabsCoord.top || tabsCoord.y) < navbarCoord.height) {
    tabs.classList.add('sticky')
    navbar.style.boxShadow = 'unset'
  } else if (bannerCoord.bottom >= tabsCoord.bottom) {
    tabs.classList.remove('sticky')
    navbar.style.boxShadow = null
  }
}
